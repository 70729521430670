import React, { useEffect, useState } from "react";
import DataTable from "../../../Utlity/dataTable/DataTable";
import FloatingAddButton from "../../../Utlity/addButton/FloatingAddButton";
import EntryForm from "./EntryForm";
import { useSelector } from "react-redux";

const Desktop = ({ modelFunction }) => {
    const leftWidth = useSelector((state) => state.global.leftWidth)

    const userTableCol = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'name', headerName: 'Name', width: window.innerWidth - 710 - leftWidth - 150, editable: true },
        { field: 'narration', headerName: 'Description', width: 150 },
        { field: 'purchase_price', headerName: 'Cost', width: 100 },
        { field: 'sale_price', headerName: 'Sale', width: 100 },
        { field: 'stock_qty', headerName: 'Stock', width: 50 },
        { field: 'stock_pices', headerName: 'Kg', width: 50 },
        { field: 'unit', headerName: 'Unit', width: 100 },
        // { field: 'active', headerName: 'Active', width: 100 },
    ];

    return (
        <>
            <DataTable
                column={userTableCol}
                data={modelFunction.data.itemList}
                title={'Product List'}
                loader={modelFunction.loading}
                action={{
                    delete: modelFunction.handelDeleteConfirm,
                    edit: modelFunction.handelEdit
                }}
            />
            <EntryForm  modelFunction={modelFunction}/>
            <FloatingAddButton action={modelFunction.handelAdd} />
        </>
    )
}

export { Desktop };
