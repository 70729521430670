import React from "react"
import { Box } from "@mui/material"
import LeftSidebar from "./LeftSidebar"
import SideBarContainer from '../SideBar/SideBarContainer'
import Navbar from "./Navbar"
import { useSelector } from 'react-redux'
import CircularLoader from "../../Utlity/loaderBuffer/CircularLoader"

const Desktop = (props) => {
    return (
        <>
            <Box display={'flex'} style={{ height: "100vh" }} >
                <LeftSidebar />
                {/* <SideBarContainer /> */}
                <Box>
                    <Navbar />
                    {props.children}
                </Box>
            </Box>
        </>
    );
}

export { Desktop };
