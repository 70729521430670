import React, { useRef, } from 'react'
import { Box, Typography, Chip, IconButton } from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import Icons from '../../components/Master/Icons';


function DataTable({ column = null, data = null, action = null, title = null, loader = null, toolBar = false }) {



    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const windowHeight = windowSize.current[1];
    const hasProperty = data.some(item => item.hasOwnProperty('active'));
    let rowsValu = Math.floor(windowHeight / (windowHeight > 685 ? 80 : 90)) || 0

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    let ActiveStatus = hasProperty ? [
        {
            field: 'status',
            headerName: 'Status',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <>
                    {params.row.active != null &&
                        <Chip label={params.row.active ? "Active" : "Disable"}
                            sx={params.row.active ? { backgroundColor: 'chip.success' } : { backgroundColor: 'chip.error' }}
                        />
                    }
                </>
            ),
        },
    ] : []

    let actionBtn = [
        {
            field: 'action',
            headerName: 'Action',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            filterable: false,
            width: 150,
            checkboxSelection: false,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => action.edit(params.row)} size="small">
                        <Icons icon='EditIcon' />
                    </IconButton >
                    <IconButton onClick={() => action.delete(params.row.id)} color="warning" size="small">
                        <Icons icon='DeleteIcon' />
                    </IconButton>
                </>
            )

        },
    ]


    const columns = [...column, ...ActiveStatus, ...actionBtn];

    return (
        <Box
            sx={{
                p: 2,
                bgcolor: 'background.default',
                // display: 'grid',
                gridTemplateColumns: { md: '1fr 1fr' },
                gap: 2,
                // margin: 1,
                // borderRadius: '10px'

            }}
        >


            <Typography variant="h6" component="div" sx={{ pb: 1 }}>
                {title && title}
            </Typography>
            <Box sx={{ height: windowSize.current[1] - 150 }}>

                <DataGrid
                    rows={data}
                    columns={columns}
                    loading={loader && loader}
                    slots={toolBar && {
                        toolbar: CustomToolbar,
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: rowsValu },
                        },
                    }}
                    pageSizeOptions={[rowsValu, rowsValu * 2, rowsValu * 4, rowsValu * 8, rowsValu * 16]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            fontWeight: 'bold !important',
                            overflow: 'visible !important',
                            backgroundColor: '#000',
                            color: '#fff'
                        },
                        "& .MuiDataGrid-sortIcon": {
                            opacity: 1,
                            color: "white",
                        },
                        "& .MuiDataGrid-menuIconButton": {
                            opacity: 1,
                            color: "white"
                        },
                    }}
                />
            </Box>
        </Box>
    )
}

export default DataTable