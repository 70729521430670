
class FaPath {

    static unit = {
        all: 'po/unit',
        add: 'po/unit',
        edit: 'po/unit',
        delete: 'po/unit/'
    }

    static party = {
        all: 'po/party',
        add: 'po/party',
        edit: 'po/party',
        delete: 'po/party/', //user id
        byName: 'po/party/byName/',
    }

    static product = {
        all: 'po/product',
        add: 'po/product',
        edit: 'po/product',
        delete: 'po/product/', //user id
        unitList: 'po/product/Units',
        byName: 'po/product/byName/',
    }

    static purchase = {
        all: 'po/purchase',
        add: 'po/purchase',
        edit: 'po/purchase',
        delete: 'po/purchase/',
    }

}

export default FaPath