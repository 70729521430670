import React, { useEffect, useRef, useState } from "react";
import FloatingAddButton from "../../../Utlity/addButton/FloatingAddButton";
import InfiniteScroll from "react-infinite-scroll-component";
import { Avatar, Box, Button, CircularProgress, Grid, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import Icons from "../../../components/Master/Icons";
import { Product } from './Product';
import { MasterInfo } from "./MasterInfo";


const DataTable = ({ data, loader, action }) => {
    const itemLoadLength = 30;
    const [count, setCount] = useState({ prev: 0, next: 0, });
    const [current, setCurrent] = useState([]);
    const [mydata, setMyData] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState("");
    const [editData, setEditData] = useState()
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const windowHeight = windowSize.current[1];

    useEffect(() => {
        //console.log("pending", model.pendingBill)
        const dt = new Promise((reslove, reject) => {
            if (data && data.length > 0) {
                reslove(data);
            } else {
                reject(Error("Promise Rejected"));
            }
        });

        dt.then(
            (data) => {
                setMyData(data);
                setCurrent(
                    data &&
                    data.slice(
                        count.prev,
                        data.length < itemLoadLength ? data.length : itemLoadLength
                    )
                );
                setCount({
                    prev: 0,
                    next:
                        data && data.length < itemLoadLength ? data.length : itemLoadLength,
                });
            },
            (err) => {
                console.log(err);
            }
        );

    }, [data]);


    const dataSlice = (data) => {

    }

    const getMoreDt = () => {

        if ((current && current.length) === (mydata && mydata.length)) {
            setHasMore(false);
            return;
        }

        setTimeout(() => {
            setCurrent(
                current &&
                current.concat(
                    mydata &&
                    mydata.slice(
                        count.prev + itemLoadLength,
                        count.next + itemLoadLength > mydata.length
                            ? mydata.length
                            : count.next + itemLoadLength
                    )
                )
            );
        }, 10);

        setCount((prevState) => ({
            prev: prevState.prev + itemLoadLength,
            next: prevState.next + itemLoadLength,
        }));
        if (hasMore) {
            setLoading("Loading...");
        }
    };


    const search = (e) => {
        let text = e.target.value
        if (text.length > 0) {
            const filteredUsers = Object.keys(data)
                .filter(key => text.includes(key))
                .reduce((obj, key) => {
                    obj[key] = data[key];
                    return obj;
                }, []);
            setCurrent(filteredUsers)
        } else {
            setCurrent(data)
        }

    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    return (
        <Box>
            <Box sx={{ margin: '4px', p: 1, bgcolor: '#fff' }}>
                <TextField id="outlined-basic" onChange={search} label="Search" variant="outlined" size="small" fullWidth />
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                style={{ overflow: "scroll" }}
                height={windowHeight - 130}
                id="scrollableDiv"
            >
                {loader &&
                    <Box sx={{ height: windowHeight, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                }

                <InfiniteScroll
                    dataLength={current && current.length}
                    pullDownToRefreshThreshold={10}
                    next={getMoreDt}
                    hasMore={hasMore}
                    // loader={<h3>{loading}</h3>}
                    scrollableTarget="scrollableDiv"
                >
                    {current && current.map((itemData, index) =>
                        <Box sx={{ margin: '4px', p: 1, bgcolor: '#FEFEFE' }} key={itemData.id}>
                            <Grid container>
                                <Grid item xs={11} >
                                    <MasterInfo item={itemData} />
                                </Grid>
                                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end', alignItems: "start" }}>
                                    <IconButton size="small" onClick={(event) => {
                                        setEditData(itemData)
                                        setAnchorEl(event.currentTarget)
                                    }}>
                                        <Icons icon='MoreVertIcon' />
                                    </IconButton >

                                    {/* <IconButton onClick={() => action.print(item.id)} size="small">
                                        <Icons icon='EditIcon' />
                                    </IconButton >
                                    <IconButton onClick={() => action.edit(item)} size="small">
                                        <Icons icon='EditIcon' />
                                    </IconButton >
                                    <IconButton onClick={() => action.delete(item.id)} color="warning" size="small">
                                        <Icons icon='DeleteIcon' />
                                    </IconButton> */}
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </InfiniteScroll>

                <Menu
                    id="basic-menu"
                    // MenuListProps={{
                    //     'aria-labelledby': 'demo-customized-button',
                    // }}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                >

                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                        action.edit(editData)
                    }}>
                        <Icons icon='EditIcon' />
                        Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                        action.preview(editData.id)
                    }}>
                        <Icons icon='FileOpenIcon' />
                        Preview
                    </MenuItem>
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                        action.delete(editData.id)
                    }}>
                        <Icons icon='DeleteIcon' />
                        Delete
                    </MenuItem>
                </Menu>
            </Box>


            {/* <AlertDialog
                open={open}
                setOpen={setOpen}
                action={() => handelDeleteUser(selectId)}
            /> */}
        </Box>
    )

}

export { DataTable };
