import React from "react";
import { Layout as HomePage } from './Home/Layout'
import { Layout as LoginPage } from './Login/Layout'
import { Layout as ConstructionPage } from './Construction/Layout'
import { Layout as UserPage } from './User/Layout'
import { Layout as Api } from "./ApiList/Layout";

import { Layout as AccountPayable } from './FA/AccountingVoucher/AccountPayable/Layout'

import { Layout as Company } from './AM/Company/Layout'
import { Layout as Branch } from './AM/Branch/Layout'
import { Layout as Product } from './PO/Product/Layout'
import { Layout as Party } from './PO/Party/Layout'
import { Layout as Purchase } from './PO/Purchase/Layout'
import { Layout as Sale } from './PO/Sale/Layout'
import { Layout as Report } from './PO/Report/Layout'
import WelcomePage from "./WelcomePage";


const Pages = ({ route }) => {
    const pages = pageList.filter((item) => item.route === route)
    return pages.length > 0 ? pages[0].page : <ConstructionPage />
}

export default Pages;


const pageList = [
    {
        name: "welcomePage",
        route: "/",
        page: <WelcomePage />
    },
    {
        name: "api",
        route: "/api",
        page: <Api />
    },
    {
        name: "login",
        route: "/login",
        page: <LoginPage />
    },
    {
        name: "registration",
        route: "/registration",
        page: <>registration</>
    },
    {
        name: "forget-password",
        route: "/forget-password",
        page: <>rorget-password</>
    },
    {
        name: "sign-up",
        route: "/sign-up",
        page: <>sign-up</>
    },
    {
        name: "Construction",
        route: "/construction",
        page: <ConstructionPage />
    },
    {
        name: "home",
        route: "/home",
        page: <HomePage />
    },
    {
        name: "user",
        route: "/user",
        page: <UserPage />
    },
    {
        name: "role",
        route: "/role",
        page: <>Role</>
    },
    {
        name: "Accounts Payable",
        route: "/accountsPayable",
        page: <AccountPayable />
    },
    {
        name: "Company",
        route: "/companyInfo",
        page: <Company />
    },
    {
        name: "Branch",
        route: "/branchInfo",
        page: <Branch />
    },
    {
        name: "Product",
        route: "/ProductInfo",
        page: <Product />
    },
    {
        name: "Party",
        route: "/PartyInfo",
        page: <Party />
    },
    {
        name: "Purchase",
        route: "/Purchase",
        page: <Purchase />
    },
    {
        name: "Sale",
        route: "/Sale",
        page: <Sale />
    }, {
        name: "Report",
        route: "/Report",
        page: <Report />
    },
];


