import React from 'react'
import PopupModal from '../../../Utlity/modal/PopupModal'
import AddProduct from './AddProduct'
import { useForm } from "react-hook-form"
import { Box, Button, TextField, Tooltip, FormLabel, Checkbox, Autocomplete, Grid, CircularProgress, MenuItem, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from "@mui/material";
import Icons from '../../Master/Icons';

const AddProductPopup = ({ parentModel, setParentModel, modelFunction }) => {
    return (
        <PopupModal
            state={modelFunction.data.showProductDialog}
            setState={() => modelFunction.handelShowProduct(!modelFunction.data.showProductDialog)}
            title="Add Product">
            <AddProduct parentModel={parentModel} setParentModel={setParentModel} modelFunction={modelFunction} />
        </PopupModal>
    )
}

export default AddProductPopup
