import React, { useContext, useEffect, useState } from "react";
import { Box, Button, TextField, Tooltip, FormLabel, Checkbox, Autocomplete, Grid, CircularProgress, MenuItem, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import Icons from '../../Master/Icons'

import dayjs from "dayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import moment from "moment"

const Desktop = ({ modelFunction }) => {

    const [saleDate, setSaleDate] = React.useState()
    const today = dayjs()
    const [inputPaymentMethod, setInputPaymentMethod] = React.useState("")
    const [inputParty, setInputParty] = React.useState("")
    const [inputProduct, setInputProduct] = React.useState("")
    const [inputProductType, setInputProductType] = React.useState("")
    // const [reportTitle, setReportTitle] = React.useState("")
    const [model, setModel] = React.useState({
        reportTitle: "",
        fromDate: "",
        toDate: "",
        paymentMethod: { code: '', name: '' },
        product: { code: '', name: '' },
        productCategory: { code: '', name: '' },
        party: { code: '', name: '' },
        serialNo: ''
    })

    const onSubmit = () => {
        modelFunction.handelPreview({
            reportTitle: model.reportTitle,
            fromDate: moment(model.fromDate.$d).format("YYYY-MM-DD"),
            toDate: moment(model.toDate.$d).format("YYYY-MM-DD"),
            paymentMethod: model.paymentMethod,
            product: model.product,
            productCategory: model.productCategory,
            party: model.party,
            serialNo: model.serialNo,
        })
    }

    return (
        <>
            {modelFunction.loading &&
                <Box sx={{ height: window.innerHeight - 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            }
            {!modelFunction.loading &&
                <>
                    <Box sx={{ p: 1, height: window.innerHeight - 120, overflow: 'auto' }}  >
                        <Grid container >
                            <Grid item xs={12} md={6} sx={{ p: 1 }}>
                                <Box sx={{ bgcolor: '#fff', borderRadius: '4px' }}  >
                                    <Box sx={{ bgcolor: '#EEE', height: '40px', display: 'flex', alignItems: 'center', p: 1 }} >
                                        Reports
                                    </Box>
                                    <Box sx={{ p: 1 }}>
                                        <RadioGroup column value={model.reportTitle} onChange={(event) => setModel((preState) => { return { ...preState, reportTitle: event.target.value } })}>
                                            {modelFunction?.data?.itemList.map((item, index) => <FormControlLabel key={index} value={item.name} control={<Radio />} label={item.name} />)}
                                        </RadioGroup>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ p: 1 }}>
                                <Box sx={{ bgcolor: '#fff', borderRadius: '4px' }}  >
                                    <Box sx={{ bgcolor: '#EEE', height: '40px', display: 'flex', alignItems: 'center', p: 1 }} >
                                        Filter
                                    </Box>
                                    <Grid container >
                                        <Grid item xs={12} md={6} sx={{ p: 1 }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    defaultValue={today}
                                                    format="DD-MM-YYYY"
                                                    views={["year", "month", "day"]}
                                                    slotProps={{
                                                        textField: {
                                                            label: "From Date",
                                                            size: "small",
                                                            fullWidth: true,
                                                            // error: errors?.sale_date ? true : false,
                                                        },
                                                    }}
                                                    value={model.fromDate}
                                                    onChange={(value) => setModel((preState) => { return { ...preState, fromDate: value } })}
                                                />
                                            </LocalizationProvider>

                                        </Grid>
                                        <Grid item xs={12} md={6} sx={{ p: 1 }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    defaultValue={today}
                                                    format="DD-MM-YYYY"
                                                    views={["year", "month", "day"]}
                                                    slotProps={{
                                                        textField: {
                                                            label: "To Date",
                                                            size: "small",
                                                            fullWidth: true,
                                                            // error: errors?.sale_date ? true : false,
                                                        },
                                                    }}
                                                    value={model.toDate}
                                                    onChange={(value) => setModel((preState) => { return { ...preState, toDate: value } })}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sx={{ p: 1 }}>
                                            <Autocomplete
                                                // onChange={handelPartyChange}
                                                size="small"
                                                options={modelFunction.data.partyList}
                                                fullWidth
                                                // defaultValue={party}
                                                value={model.party}
                                                inputValue={inputParty}
                                                onChange={(event, newValue) => setModel({ ...model, party: newValue })}
                                                onInputChange={(event, newInputValue) => setInputParty(newInputValue)}
                                                id="PartyAutocomplete"
                                                getOptionLabel={(option) => option?.name}
                                                // onInputChange={(event) => handelPartyInputChange(event)}
                                                // renderInput={(params) => <TextField {...params} label="Party" />}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={"Party"} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ p: 1 }}>
                                            <Autocomplete
                                                size="small"
                                                options={modelFunction.data.paymentMethodList}
                                                fullWidth
                                                value={model.paymentMethod}
                                                inputValue={inputPaymentMethod}
                                                onChange={(event, newValue) => setModel({ ...model, paymentMethod: newValue })}
                                                onInputChange={(event, newInputValue) => setInputPaymentMethod(newInputValue)}
                                                id="PaymentMethodAutocomplete"
                                                getOptionLabel={(option) => option?.name}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={"Payment Method"} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ p: 1 }}>
                                            <Autocomplete
                                                size="small"
                                                options={modelFunction.data.productList}
                                                fullWidth
                                                value={model.product}
                                                inputValue={inputProduct}
                                                onChange={(event, newValue) => setModel({ ...model, product: newValue })}
                                                onInputChange={(event, newInputValue) => setInputProduct(newInputValue)}
                                                id="productAutocomplete"
                                                getOptionLabel={(option) => option?.name}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={"Product"} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ p: 1 }}>
                                            <Autocomplete
                                                size="small"
                                                options={modelFunction.data.productTypeList}
                                                fullWidth
                                                value={model.productType}
                                                inputValue={inputProductType}
                                                onChange={(event, newValue) => setModel({ ...model, productType: newValue })}
                                                onInputChange={(event, newInputValue) => setInputProductType(newInputValue)}
                                                id="productTypeAutocomplete"
                                                getOptionLabel={(option) => option?.name}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={"Product Type"} />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ p: 1 }} >
                                            <TextField
                                                name="serial"
                                                label={"Serial"}
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                value={model.serialNo}
                                                onChange={(event) => setModel({ ...model, serialNo: event.target.value })}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, bgcolor: '#EEE' }}>
                        <Tooltip title="Save">
                            <Button type="submit" variant="contained" color="primary" size='small' onClick={onSubmit} >
                                <Icons icon='FileOpenIcon' />
                                Preview
                            </Button>
                        </Tooltip>
                    </Grid>
                </>
            }
        </>
    )
}

export { Desktop };
