import * as React from 'react';
import { Box, Button, TextField, Tooltip, FormLabel, Checkbox, Autocomplete, Grid, CircularProgress, MenuItem, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from "@mui/material";
import Icons from '../../Master/Icons';
import { useForm } from "react-hook-form"
import { useSelector } from 'react-redux';
import moment from "moment";
import { useDispatch } from 'react-redux';
import { setLoader, setCircularLoader, setNotification, setDialogMessage } from '../../../globalSlice'


const AddProduct = ({ parentModel, setParentModel, modelFunction }) => {
    const dispatch = useDispatch();
    const [product, setProduct] = React.useState(null);
    const [inputProduct, setInputProduct] = React.useState("");
    const [productList, setProductList] = React.useState([]);
    const [isAdd, setIsAdd] = React.useState(true);

    const [model, setModel] = React.useState({
        productName: "",
        pices: "",
        qty: "",
        salePrice: "",
        serial_no: '',
        product_id: 0,
        row_id: 0
    });

    const [error, setError] = React.useState({
        productNameError: "",
        picesError: "",
        qtyError: "",
        salePriceError: ""
    });

    const onSubmit = () => {
        setError((prevState) => {
            var isValid = true;
            var tempData = {
                productNameError: "",
                picesError: "",
                qtyError: "",
                salePriceError: ""
            }

            if (model?.productName?.length === 0) {
                isValid = false;
                tempData = {
                    ...tempData,
                    productNameError: "The product is required"
                };
            }

            if (!Number(model?.pices)) {
                isValid = false;
                tempData = {
                    ...tempData,
                    picesError: "Inpurt number here"
                };
            }

            if (!Number(model?.qty)) {
                isValid = false;
                tempData = {
                    ...tempData,
                    qtyError: "Inpurt number here"
                };
            }

            if (!Number(model?.salePrice)) {
                isValid = false;
                tempData = {
                    ...tempData,
                    salePriceError: "Inpurt number here"
                };
            }


            if (isValid) {

                // modelFunction.handelAddProduct({
                //     productModels: product,
                //     pices: Number(model.pices),
                //     qty: Number(model.qty),
                //     sale_price: Number(model.salePrice)
                // });

                setParentModel((prevState) => {

                    var data = prevState.saleChildModels;
                    var isUpdate = false

                    if (data && data.length > 0) {
                        data.map((valItme) => {
                            if (valItme?.productModels?.id === product?.id) {
                                valItme.product_id = product?.id
                                valItme.qty = Number(model.qty)
                                valItme.pices = Number(model.pices)
                                valItme.sale_price = Number(model.salePrice)
                                isUpdate = true
                            }
                        });
                    }

                    if (!isUpdate) {
                        data.push({
                            row_id: data && data.length > 0 ? data.length : 0,
                            productModels: product,
                            pices: Number(model.pices),
                            qty: Number(model.qty),
                            sale_price: Number(model.salePrice),
                            product_id: product?.id,
                            serial_no: ''
                        });
                    }

                    return {
                        ...prevState,
                        saleChildModels: data
                    };
                })

                setIsAdd(false)
                modelFunction.handelShowProduct(false)
            }
            return tempData;
        })
    }

    const handelChangeProduct = (newValue) => {
        setProduct(newValue);
        if (newValue && newValue?.id && newValue?.name) {
            setModel({
                ...model,
                productName: newValue?.name,
                pices: 0,
                qty: newValue?.lot_qty,
                salePrice: newValue?.sale_price
            })
            setIsAdd(true)

            var data = parentModel.saleChildModels
            if (data && data.length > 0) {
                data.map((valItme) => {
                    if (valItme?.productModels?.id === newValue?.id) {
                        setModel({
                            ...model,
                            productName: valItme?.productModels?.name,
                            pices: valItme?.pices,
                            qty: valItme?.qty,
                            salePrice: valItme?.sale_price
                        })
                        setIsAdd(false)
                        return
                    }
                });
            }
        }
    }

    React.useEffect(() => {
        if (modelFunction.data.editProduct) {
            handelChangeProduct(modelFunction.data.editProduct)
        }
    }, [modelFunction.data.editProduct])

    return (
        <Grid container >
            <Grid item xs={12} md={4} sx={{ p: 1 }}>
                <Autocomplete
                    size="small"
                    options={modelFunction.data.productList}
                    fullWidth
                    value={product}
                    inputValue={inputProduct}
                    onChange={(event, newValue) => handelChangeProduct(newValue)}
                    onInputChange={(event, newInputValue) => setInputProduct(newInputValue)}
                    id="ProductAutocomplete"
                    getOptionLabel={(option) => option?.name}
                    renderInput={(params) => (
                        <TextField {...params} label={"Select Product"}
                            helperText={error.productNameError}
                            error={error.productNameError ? true : false}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={2} sx={{ p: 1 }}>
                <TextField
                    name="pices"
                    label={"Pices"}
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={model.pices}
                    helperText={error.picesError}
                    error={error.picesError ? true : false}
                    onChange={(event) => setModel({ ...model, pices: event.target.value })}
                />
            </Grid>
            <Grid item xs={12} md={2} sx={{ p: 1 }}>
                <TextField
                    name="qty"
                    label={"Qty"}
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={model.qty}
                    helperText={error.qtyError}
                    error={error.qtyError ? true : false}
                    onChange={(event) => setModel({ ...model, qty: event.target.value })}
                />
            </Grid>
            <Grid item xs={12} md={2} sx={{ p: 1 }}>
                <TextField
                    name="salePrice"
                    label={"Sale Price"}
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={model.salePrice}
                    helperText={error.salePriceError}
                    error={error.salePriceError ? true : false}
                    onChange={(event) => setModel({ ...model, salePrice: event.target.value })}
                />
            </Grid>

            <Grid item xs={12} md={2} sx={{ display: 'flex', p: 1, maxHeight: 50 }}>
                {isAdd && <Tooltip title="Add Product" >
                    <Button type="button" variant="contained" color="secondary" size='small' onClick={onSubmit} >
                        <Icons icon='AddIcon' />
                        Add
                    </Button>
                </Tooltip>}

                {!isAdd && <Tooltip title="Add Product" >
                    <Button type="button" variant="contained" color="secondary" size='small' onClick={onSubmit} >
                        <Icons icon='PlaylistAddCheckIcon' />
                        Update
                    </Button>
                </Tooltip>}
            </Grid>
        </Grid>
    );
}

export default AddProduct;
