import React, { useEffect, useState } from "react";
import useWindowSize from '../../../Utlity/useWindowSize';
import { Layout as MasterLayout } from '../../Master/Layout';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';
import useSendRequest from "../../../constants/customeHelper/useSendRequest";
import FaPath from "../path/FaPath"
import { setConfirmDailog, setDialogMessage } from "../../../globalSlice";
import { useDispatch, useSelector } from "react-redux";

const Layout = () => {
    const dispatch = useDispatch();
    // const [allData, setAllData] = useState([])
    // const [editData, setEditData] = useState(null)
    // const [formTitle, setFormTitle] = useState("")
    // const [rightSidebarState, setRightSidebarState] = useState(false)
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState({
        itemList: [],
        item: null,
        formTitle: "",
        rightSidebarState: false,
        sizeList: []
    })


    const handelDelete = async (id) => {
        await sendDeleteRequest(FaPath.product.delete + id).then(valu => {
            console.log('delete done')
        }).catch(err => {
            console.log('delete error', err.message)
        });
    }
    // const handelDeleteConfirm = (id) => {
    //     dispatch(setConfirmDailog({
    //         type: 'error',
    //         message: "Are you sure you want to delete",
    //         action: () => handelDelete(id)
    //     }));
    // }

    // const handelEdit = (data) => {
    //     setFormTitle("Edit Company")
    //     setRightSidebarState(true)
    //     setEditData(data)
    // }

    // const handelAdd = () => {
    //     setFormTitle("Add Company")
    //     setEditData(null)
    //     setRightSidebarState(true)
    // }

    const {
        sendPostRequest,
        sendPutRequest,
        sendDeleteRequest,
        sendCustomGetRequest,
    } = useSendRequest()


    const modelFunction = {
        handelDeleteConfirm: (id) => {
            dispatch(setConfirmDailog({
                type: 'error',
                message: "Are you sure you want to delete",
                action: () => handelDelete(id)
            }));
        },
        handelEdit: (val) => {
            setData((prev) => {
                return {
                    ...prev,
                    formTitle: "Edit Product",
                    rightSidebarState: true,
                    item: val
                };
            });
        },
        handelAdd: () => {
            setData((prev) => {
                return {
                    ...prev,
                    formTitle: "Add Product",
                    rightSidebarState: true,
                    item: null
                };
            });
        },
        setRightSidebarState: (val) => {
            setData((prev) => {
                return {
                    ...prev,
                    rightSidebarState: val
                };
            });
        },
        data: data,
        // setData: setData,
        // sendPostRequest: sendPostRequest,
        // sendPutRequest: sendPutRequest,
        loading: loader,
        handelInsert: async (fromData) => {
            return await sendPostRequest(FaPath.product.add, fromData)
        },
        handelUpdate: async (fromData) => {
            return await sendPutRequest(FaPath.product.edit, fromData)
        },
        handelSave: async (fromData) => {
            return await sendPostRequest(FaPath.product.add, fromData)
        },
    }

    let isInitRequest = true;
    useEffect(() => {

        if (isInitRequest) {
            isInitRequest = false
            // var modelItem = modelFunction.data.item
            try {
                setTimeout(() => {
                    Promise.all([
                        sendCustomGetRequest(FaPath.product.unitList).then((value) => setData((prev) => {
                            return {
                                ...prev,
                                sizeList: value,
                            };
                        })),
                        sendCustomGetRequest(FaPath.product.all).then((value) => setData((prev) => {
                            return {
                                ...prev,
                                itemList: value.sort((a, b) => b.id - a.id),
                            };
                        }))
                    ]).then(() => setLoader(false));
                }, 100)
            }
            catch (err) {
                console.log(err);
            }
        }

    }, []);


    return (
        <>
            {useWindowSize().desktop && <MasterLayout><Desktop modelFunction={modelFunction} /></MasterLayout>}
            {useWindowSize().mobile && <MasterLayout><Mobile modelFunction={modelFunction} /></MasterLayout>}
        </>
    );
}

export { Layout };
