import * as React from 'react';
import { Box, Button, TextField, Tooltip, FormLabel, Checkbox, Grid, CircularProgress, MenuItem } from "@mui/material";
import Icons from '../../Master/Icons';
import { useForm } from "react-hook-form"
import { useSelector } from 'react-redux';
import urlPath from '../path/FaPath';
import FormHeader from '../../../Utlity/FormHeader';


const EntryForm = ({ modelFunction }) => {

    const { register, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm();
    let loaderStatus = useSelector((state) => state.global.loadingSataus);
    const [editData, setEditData] = React.useState(modelFunction.data.item);

    React.useEffect(() => {
        console.log(modelFunction.data.item);
        setEditData(modelFunction.data.item)
    }, [modelFunction.data.item])

    React.useEffect(() => {
        reset()
        if (editData == null) {
            setValue("id", 0)
            setValue("name", "")
            setValue("narration", "")
        } else {
            console.log(editData);
            setValue("id", editData && editData.id)
            setValue("name", editData && editData.name)
            setValue("narration", editData && editData.narration)
        }

    }, [editData])

    const onSubmit = data => {
        editData == null ? handelAdd(data) : handelEdit(data)
    }

    const closeForm = () => {

    }

    const handelAdd = async (data) => {
        let fromData = {
            ...data,
            id: 0,
            active: data.active === "true" ? true : false
        }
        await modelFunction.handelInsert(fromData)
            .then(valu => {
                !loaderStatus && modelFunction.setRightSidebarState(false)
                reset()
                console.log('form submited', valu)
            }).catch(err => {
                console.log('form error', err)
            })

    }

    const handelEdit = async (data) => {
        let fromData = {
            ...data,
            id: editData && editData.id,
            active: data.active === "true" ? true : false
        }

        await modelFunction.handelUpdate(fromData)
            .then(valu => {
                modelFunction.setRightSidebarState(false)
                reset()
                console.log('form submited', valu)
            }).catch(err => {
                
                console.log('form error', err)
            })
    }

    return (
        <FormHeader
            title={modelFunction.data.formTitle}
            setState={modelFunction.setRightSidebarState}
            state={modelFunction.data.rightSidebarState} >
            <Box sx={{ p: 2 }}>
                <Box flexGrow={1} height="20px"></Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormLabel id="demo-radio-buttons-group-label">Name</FormLabel>
                    <TextField
                        variant="outlined"
                        name="name"  {...register('name', { required: true })}
                        helperText={errors.name ? "Name is required !" : ""}
                        error={errors.name ? true : false}
                        fullWidth
                    />
                    <Box height="20px"></Box>

                    <FormLabel id="demo-radio-buttons-group-label">Narration</FormLabel>
                    <TextField
                        name="narration"
                        variant="outlined" {...register('narration', { required: false })}
                        // helperText={errors.narration ? "Narration is required !" : ""}
                        // error={errors.narration ? true : false}
                        fullWidth
                    />
                    <Box height="20px"></Box>
                
                    <Grid container>
                        <Grid item xs={6}>
                            <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
                            <Checkbox
                                defaultChecked={editData && editData.active || true}
                                name='active'
                                value="true"
                                {...register('active')}
                                // onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {loaderStatus ?
                                <CircularProgress />
                                :
                                <Tooltip title="Save">
                                    <Button type="submit" variant="contained" color="primary" >
                                        <Icons icon='SaveIcon' />
                                        Save
                                    </Button>
                                </Tooltip>
                            }
                        </Grid>
                    </Grid>
                    <Box flexGrow={1} />
                </form>

            </Box>
        </FormHeader>
    );
}

export default EntryForm;
