import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoader,
  setCircularLoader,
  setNotification,
  setDialogMessage,
  setConfirmDailog,
  setLogin,
} from "../../globalSlice";

import { useNavigate } from "react-router-dom";

const useSendRequest = (url = null) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = useSelector((state) => state.global.isLogin);

  useEffect(() => {
    isLogin && reloadData();
  }, [isLogin]);

  const hendleOut = (event) => {
    dispatch(
      setConfirmDailog({
        type: "warning",
        message: "Session Time Out !",
        subMessage:
          "To continue your work please login again with your user name and password thank you",
      })
    );
    localStorage.setItem("user", null);
    localStorage.setItem("userProfileTemplate", null);
    dispatch(setLogin(false));
    navigate("/login");
  };

  /**
   * reload data
   */
  const reloadData = () => {
    return url && sendGetRequest();
  };

  /**
   * all laoder off
   */
  const buffer = (status) => {
    setLoading(status);
    dispatch(setLoader(status));
    dispatch(setCircularLoader(status));
  };

  /**
   * error handaling
   */
  const handelError = (error) => {
    setLoading(false);
    dispatch(setLoader(false));
    dispatch(setCircularLoader(false));
    if (error.response?.status === 401) {
      hendleOut();
    }
    if (error.response?.status === 400 || error.response?.status === 500) {
      dispatch(
        setNotification({ type: "error", message: error?.response?.data })
      );
    } else {
      console.log("error create", error);
      dispatch(
        setNotification({
          type: "serverError",
          message:
            `${error?.response?.statusText && error?.response?.statusText} ! ` +
            error?.message,
        })
      );
    }
  };

  /**
   * sent print request
   */
  const sendPrintRequest = (url) => {
    dispatch(setCircularLoader(true));
    // return
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          method: "GET",
          responseType: "arraybuffer",
        })
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          const pdfUrl = URL.createObjectURL(pdfBlob);

          window.open(pdfUrl);
          resolve(res.data);

          dispatch(setCircularLoader(false));
        })
        .catch((error) => {
          handelError(error);
          dispatch(setCircularLoader(false));
          dispatch(
            setDialogMessage({ status: "error", message: error.message })
          );
          reject(error);
        });
    });
  };

  /**
   * get request sent
   */
  const sendGetRequest = () => {
    dispatch(setLoader(true));
    //console.log('loading ON')
    // buffer(true);

    axios
      .get(url)
      .then((resData) => {
        console.log("custome hook data____", resData.data);
        setResponse(resData.data);
        buffer(false);
        console.log("loading OFF", resData);
      })
      .catch((e) => {
        handelError(e);

        console.log("loading OFF", e.response);
      });
  };

  /**
   * post request sent
   */
  const sendPostRequest = (url, data) => {
    buffer(true);

    return new Promise((resolve, reject) => {
      // const timeout = setTimeout(() => {

      axios
        .post(url, data)
        .then((response) => {
          setLoading(false);
          buffer(false);

          dispatch(setNotification({ message: response.data }));

          reloadData();
          resolve(response.data);
        })
        .catch((error) => {
          handelError(error);
          setLoading(false);
          reject(error);
        });

      // }, 5000);
      // return () => clearTimeout(timeout);
    });
  };

  /**
   * put request sent
   */
  const sendPutRequest = (url, data) => {
    dispatch(setLoader(true));
    buffer(true);
    return new Promise((resolve, reject) => {
      // const timeout = setTimeout(() => {

      axios
        .put(url, data)
        .then((response) => {
          // dispatch(setLoader(false))
          // buffer(false);
          dispatch(setNotification({ message: "User Update" }));
          reloadData();
          resolve(response.data);
        })
        .catch((error) => {
          handelError(error);
          dispatch(setLoader(false));
          buffer(false);
          reject(error);
        });

      // }, 5000);
      // return () => clearTimeout(timeout);
    });
  };

  /**
   * delete request sent
   */
  const sendDeleteRequest = (url) => {
    // dispatch(setLoader(true))
    // buffer(true);
    dispatch(setCircularLoader(true));
    return new Promise((resolve, reject) => {
      // const timeout = setTimeout(() => {

      axios
        .delete(url)
        .then((response) => {
          // buffer(false);
          reloadData();
          dispatch(setNotification("Item deleted !"));
          // dispatch(setLoader(false))
          resolve(response.data);
        })
        .catch((error) => {
          //session out
          handelError(error);
          // dispatch(setDialogMessage({ status: 'error', message: error.response.data }));
          buffer(false);
          reject(error);
        });

      // }, 5000);
      // return () => clearTimeout(timeout);
    });
  };

  const sendCustomGetRequest = (url, isResponseChnage = false) => {
    setLoading(true);
    buffer(true)
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          setLoading(false);
          isResponseChnage && setResponse(response.data);
          buffer(false);
          resolve(response.data);
        })
        .catch((error) => {
          //session out
          handelError(error);
          setLoading(false);
          reject(error);
        });
    });
  };


  const getApiRequest = (url, requestType = 'get', data = null) => {
    return new Promise((resolve, reject) => {
      if (requestType === 'post') {
        axios.post(url, data).then((response) => resolve(response.data)).catch((error) => reject(error))
      } else if (requestType === 'put') {
        axios.put(url, data).then((response) => resolve(response.data)).catch((error) => reject(error))
      } else if (requestType === 'delete') {
        axios.delete(url).then((response) => resolve(response.data)).catch((error) => reject(error))
      } else if (requestType === 'preview') {
        axios.get(url, { responseType: "arraybuffer" }).then((response) => resolve(response.data)).catch((error) => reject(error))
      } else if (requestType === 'previewPost') {
        axios.post(url, data, { responseType: "arraybuffer" }).then((response) => resolve(response.data)).catch((error) => reject(error))
      } else {
        axios.get(url).then((response) => resolve(response.data)).catch((error) => reject(error))
      }
    });
  }

  return {
    sendPrintRequest,
    sendCustomGetRequest,
    sendGetRequest,
    sendPostRequest,
    sendPutRequest,
    sendDeleteRequest,
    response,
    loading,
    error,
    getApiRequest
  };
};

export default useSendRequest;
