import React, { useEffect, useState } from "react"
import { Layout as MasterLayout } from '../../Master/Layout';
import { Desktop } from './Desktop';
import useSendRequest from "../../../constants/customeHelper/useSendRequest";
import { setConfirmDailog, setDialogMessage, setCircularLoader, setNotification } from "../../../globalSlice";
import { useDispatch, useSelector } from "react-redux";


const Layout = () => {
    const dispatch = useDispatch()
    const { getApiRequest, sendPrintRequest } = useSendRequest()
    const [loader, setLoader] = useState(false)

    const [data, setData] = useState({
        itemList: [],
        item: null,
        editProduct: null,
        formTitle: "",
        showBottomDialog: false,
        showProductDialog: false,
        entryFormLoader: false,
        partyList: [],
        productList: [],
    })

    const loadList = async () => {
        await getApiRequest("po/sale").then((value) => setData((prev) => {
            return {
                ...prev,
                itemList: value.sort((a, b) => b.id - a.id),
            }
        }))
    }

    const handelDelete = async (id) => {
        dispatch(setCircularLoader(true))
        await getApiRequest("po/sale/" + id, "delete").then(saveMsg => {
            loadList().then(() => {
                dispatch(setCircularLoader(false))
                dispatch(setNotification({ message: saveMsg }));
            })
        }).catch(err => {
            dispatch(setCircularLoader(false))
            dispatch(setDialogMessage({ status: 'error', message: err?.response?.data }))
        })
    }

    const modelFunction = {
        handelDeleteConfirm: (accountId, id) => {
            dispatch(setConfirmDailog({
                type: 'error',
                message: "Are you sure you want to delete",
                action: () => handelDelete(accountId, id)
            }))
        },
        handelEdit: (val) => {
            setData((prev) => {
                return {
                    ...prev,
                    formTitle: "Edit Sale",
                    showBottomDialog: true,
                    entryFormLoader: true,
                    item: null,
                    editProduct: null
                };
            })
            getApiRequest('po/sale/' + val.id).then((Sale) => {
                if (Sale) {
                    setData((prev) => {
                        return {
                            ...prev,
                            entryFormLoader: false,
                            item: Sale
                        };
                    })
                }
            })
        },
        handelAdd: () => {
            setData((prev) => {
                return {
                    ...prev,
                    formTitle: "Add Sale",
                    showBottomDialog: true,
                    entryFormLoader: false,
                    item: null,
                    editProduct: null
                };
            })
        },
        setShowBottomDialog: (val) => {
            setData((prev) => {
                return {
                    ...prev,
                    showBottomDialog: val
                };
            })
        },
        data: data,
        loading: loader,
        handelSave: async (val) => {
            dispatch(setCircularLoader(true))
            await getApiRequest("po/sale", "post", val).then((saveMsg) => {
                setData((prev) => {
                    return {
                        ...prev,
                        showBottomDialog: false
                    };
                })
                loadList().then(() => {
                    dispatch(setCircularLoader(false))
                    dispatch(setNotification({ message: saveMsg }));
                })
            }).catch(err => {
                dispatch(setCircularLoader(false))
                dispatch(setDialogMessage({ status: 'error', message: err?.response?.data }))
            })
        },
        handelShowProduct: (val) => {
            setData((prev) => {
                return {
                    ...prev,
                    showProductDialog: val
                };
            })
        },
        handelEditProduct: (val) => {
            setData((prev) => {
                return {
                    ...prev,
                    editProduct: val
                }
            })
        },
        handelAddProduct: (val) => {
            setData((prev) => {
                var itemData = prev.item;
                var data = prev.item.SaleChildModels;
                var isUpdate = false

                if (data && data.length > 0) {
                    data.map((valItme) => {
                        if (valItme?.productModels?.id === val?.productModels?.id) {
                            valItme.qty = val.qty
                            valItme.pices = val.pices
                            valItme.Sale_price = val.Sale_price
                            isUpdate = true
                        }
                    });
                }

                if (!isUpdate) {
                    data.push(val);
                }

                return {
                    ...prev,
                    item: { ...itemData, SaleChildModels: data }
                };
            })
        },
        handelPreview: (val) => {
            getApiRequest("rp/InventoryReport/SaleById?id=" + val, "preview").then((returnVal) => {
                const pdfBlob = new Blob([returnVal], { type: "application/pdf" });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl);
            })
        },
        getApiRequest: getApiRequest
    }

    let isInitRequest = true;
    useEffect(() => {

        if (isInitRequest) {
            isInitRequest = false
            // dispatch(setCircularLoader(true))
            setLoader(true)
            // var modelItem = modelFunction.data.item
            setTimeout(() => {
                Promise.all([
                    loadList(),
                    getApiRequest("po/party").then((value) => setData((prev) => {
                        return {
                            ...prev,
                            partyList: value.sort((a, b) => b.id - a.id),
                        };
                    })),
                    getApiRequest("po/product").then((value) => setData((prev) => {
                        return {
                            ...prev,
                            productList: value.sort((a, b) => b.id - a.id),
                        };
                    }))
                ]).then(() => {
                    // dispatch(setCircularLoader(false))
                    setLoader(false)
                }).catch((err) => {
                    dispatch(setDialogMessage({ status: 'error', message: err }))
                })
            }, 10)
        }

    }, [])

    return (
        <>
            <MasterLayout><Desktop modelFunction={modelFunction} /></MasterLayout>
        </>
    )
}

export { Layout };
