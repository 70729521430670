import React from 'react';

export default function useWindowSize() {
    // const isSSR = typeof window !== "undefined";
    const [windowSize, setWindowSize] = React.useState({
        // width: isSSR ? 1200 : window.innerWidth,
        // height: isSSR ? 800 : window.innerHeight,
        mobile: false,
        desktop: true
    });

    function changeWindowSize() {
        setWindowSize({
            // width: window.innerWidth,
            // height: window.innerHeight,
            mobile: window.innerWidth <= 800 ? true : false,
            desktop: window.innerWidth > 800 ? true : false
        });
    }

    React.useEffect(() => {
        changeWindowSize();
        window.addEventListener("resize", changeWindowSize);
        // window.addEventListener('DOMContentLoaded', changeWindowSize);
        return () => {
            window.removeEventListener("resize", changeWindowSize);
            // window.removeEventListener("DOMContentLoaded", changeWindowSize);
        };
    }, []);

    return windowSize;
}