import React, { useEffect, useState } from "react";
import useWindowSize from '../../../Utlity/useWindowSize';
import { Layout as MasterLayout } from '../../Master/Layout';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';
import useSendRequest from "../../../constants/customeHelper/useSendRequest";
import FaPath from "../path/FaPath"
import { setConfirmDailog, setDialogMessage } from "../../../globalSlice";
import { useDispatch, useSelector } from "react-redux";

const Layout = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState({
        itemList: [],
        item: null,
        formTitle: "",
        rightSidebarState: false
    })

    const {
        sendPostRequest,
        sendPutRequest,
        sendDeleteRequest,
        response,
        loading
    } = useSendRequest(FaPath.party.all)


    const modelFunction = {
        handelDeleteConfirm: (id) => {
            dispatch(setConfirmDailog({
                type: 'error',
                message: "Are you sure you want to delete",
                action: () => async (id) => {
                    await sendDeleteRequest(FaPath.product.delete + id).then(valu => {
                        console.log('delete done')
                    }).catch(err => {
                        console.log('delete error', err.message)
                    });
                }
            }));
        },
        handelEdit: (val) => {
            console.log(val)
            setData((prev) => {
                return {
                    ...prev,
                    formTitle: "Edit Party",
                    rightSidebarState: true,
                    item: val
                };
            });
        },
        handelAdd: () => {
            setData((prev) => {
                return {
                    ...prev,
                    formTitle: "Add Party",
                    rightSidebarState: true,
                    item: null
                };
            });
        },
        setRightSidebarState: (val) => {
            setData((prev) => {
                return {
                    ...prev,
                    rightSidebarState: val
                };
            });
        },
        data: data,
        loading: loading,
        handelInsert: async (fromData) => {
            return await sendPostRequest(FaPath.party.add, fromData)
        },
        handelUpdate: async (fromData) => {
            return await sendPutRequest(FaPath.party.edit, fromData)
        }
    }

    useEffect(() => {
        setData((prev) => {
            return {
                ...prev,
                itemList: response.sort((a, b) => b.id - a.id),
            };
        });
    }, [response]);

    return (
        <>
            {useWindowSize().desktop && <MasterLayout><Desktop modelFunction={modelFunction} /></MasterLayout>}
            {useWindowSize().mobile && <MasterLayout><Mobile modelFunction={modelFunction} /></MasterLayout>}
        </>
    );
}

export { Layout };
