import * as React from 'react';
import { Box, Button, TextField, Tooltip, FormLabel, Checkbox, Grid, CircularProgress, MenuItem } from "@mui/material";
import Icons from '../../Master/Icons';
import { useForm } from "react-hook-form"
import { useSelector } from 'react-redux';
import urlPath from '../path/FaPath';
import FormHeader from '../../../Utlity/FormHeader';


const EntryForm = ({ modelFunction }) => {

    const { register, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm();
    let loaderStatus = useSelector((state) => state.global.loadingSataus);
    const [editData, setEditData] = React.useState(modelFunction.data.item);

    React.useEffect(() => {
        setEditData(modelFunction.data.item)
    }, [modelFunction.data.item])

    React.useEffect(() => {
        reset()
        if (editData == null) {
            setValue("id", 0)
            setValue("name", "")
            setValue("narration", "")
            setValue("purchase_price", 0)
            setValue("sale_price", 0)
            setValue("unit", "")
            setValue("active", false)
            // setValue("bar_code", "")
        } else {
            setValue("id", editData && editData.id)
            setValue("name", editData && editData.name)
            setValue("narration", editData && editData.narration)
            setValue("purchase_price", editData && editData.purchase_price)
            setValue("sale_price", editData && editData.sale_price)
            setValue("unit", editData && editData.unit)
            setValue("bar_code", editData && editData.bar_code)
            // setValue("active", editData && editData.active)
        }

    }, [editData])

    const onSubmit = data => {
        // editData == null ? handelAdd(data) : handelEdit(data)
        console.log(data)
        handelSave(data)
    }

    const closeForm = () => {

    }

    // user add
    const handelAdd = async (data) => {
        let fromData = {
            ...data,
            id: 0,
            active: data.active === "true" ? true : false
        }
        console.log(fromData);
        await modelFunction.handelInsert(fromData)
            .then(valu => {
                !loaderStatus && modelFunction.setRightSidebarState(false)
                reset()
                console.log('form submited', valu)
            }).catch(err => {
                console.log('form error', err)
            })

    }

    //edit user
    const handelEdit = async (data) => {
        let fromData = {
            ...data,
            id: editData && editData.id,
            active: data.active === "true" ? true : false
        }

        await modelFunction.handelUpdate(fromData)
            .then(valu => {
                modelFunction.setRightSidebarState(false)
                reset()
                console.log('form submited', valu)
            }).catch(err => {
                
                console.log('form error', err)
            })
    }

    const handelSave = async (data) => {
        let fromData = {
            ...data,
            active: data.active === "true" ? true : false
        }

        await modelFunction.handelSave(fromData) 
            .then(valu => {
                modelFunction.setRightSidebarState(false)
                reset()
                console.log('form submited', valu)
            }).catch(err => {
                
                console.log('form error', err)
            })
    }

    return (
        <FormHeader
            title={modelFunction.data.formTitle}
            setState={modelFunction.setRightSidebarState}
            state={modelFunction.data.rightSidebarState} >
            <Box sx={{ p: 2 }}>
                <Box flexGrow={1} height="20px"></Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormLabel id="demo-radio-buttons-group-label">Name</FormLabel>
                    <TextField
                        variant="outlined"
                        name="name"  {...register('name', { required: true })}
                        helperText={errors.name ? "Name is required !" : ""}
                        error={errors.name ? true : false}
                        fullWidth
                    />
                    <Box height="20px"></Box>

                    <FormLabel id="demo-radio-buttons-group-label">Narration</FormLabel>
                    <TextField
                        name="narration"
                        variant="outlined" {...register('narration', { required: false })}
                        // helperText={errors.narration ? "Narration is required !" : ""}
                        // error={errors.narration ? true : false}
                        fullWidth
                    />
                    <Box height="20px"></Box>
                    <FormLabel id="demo-radio-buttons-group-label">Purchase Price</FormLabel>
                    <TextField
                        name="purchase_price"
                        variant="outlined" {...register('purchase_price', { required: true })}
                        helperText={errors.purchase_price ? "Purchase Price is required !" : ""}
                        error={errors.purchase_price ? true : false}
                        fullWidth
                    />
                    <Box height="20px"></Box>
                    <FormLabel id="demo-radio-buttons-group-label">Sale Price</FormLabel>
                    <TextField
                        name="sale_price"
                        variant="outlined" {...register('sale_price', { required: true })}
                        helperText={errors.sale_price ? "Sale Price is required !" : ""}
                        error={errors.sale_price ? true : false}
                        fullWidth
                    />
                    <Box height="20px"></Box>

                    <FormLabel id="demo-radio-buttons-group-label">Unit</FormLabel>
                    <TextField
                        id="outlined-select-currency"
                        select
                        variant="outlined"
                        name="unit"  {...register('unit', { required: false })}
                        fullWidth
                        defaultValue={editData ? editData.unit : ""}
                    >
                        {modelFunction.data.sizeList && modelFunction.data.sizeList.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Box height="20px"></Box>
                    <Grid container>
                        <Grid item xs={6}>
                            <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
                            <Checkbox
                                defaultChecked={editData && editData.active || true}
                                name='active'
                                value="true"
                                {...register('active')}
                                // onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {loaderStatus ?
                                <CircularProgress />
                                :
                                <Tooltip title="Save">
                                    <Button type="submit" variant="contained" color="primary" >
                                        <Icons icon='SaveIcon' />
                                        Save
                                    </Button>
                                </Tooltip>
                            }
                        </Grid>
                    </Grid>
                    <Box flexGrow={1} />
                </form>

            </Box>
        </FormHeader>
    );
}

export default EntryForm;
