import React, { useEffect, useRef, useState } from "react";
import FloatingAddButton from "../../../Utlity/addButton/FloatingAddButton";
import InfiniteScroll from "react-infinite-scroll-component";
import { Avatar, Box, Button, CircularProgress, Grid, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled, alpha } from '@mui/material/styles';

import moment from 'moment';
import Icons from "../../../components/Master/Icons";


const useStyles = createTheme(({
    card: {
        width: '100%',
        // height: 150,
        backgroundColor: 'primary.white',
        borderRadius: '8px',
        mt: 1,
        boxShadow: 2
    }
}));


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 150,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow: 'rgba(152, 161, 168, 1.2) 1px 1px 3px -1px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


const DataTableMobile = ({ data, loader, action }) => {
    // const { dataModel } = useContext(RawStoreDashBoardContext);
    // const [model, setModel] = dataModel;
    const itemLoadLength = 30;
    const [count, setCount] = useState({
        prev: 0,
        next: 0,
    });
    const [current, setCurrent] = useState([]);

    const [mydata, setMyData] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState("");
    const [editData, setEditData] = useState()
    const hasProperty = data.some(item => item.hasOwnProperty('active'));
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const windowHeight = windowSize.current[1];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [searchQuery, setSearchQuery] = useState('');

    const handleClick = (event, item) => {
        setEditData()
        setAnchorEl(event.currentTarget);
        setEditData(item)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        //console.log("pending", model.pendingBill)
        const dt = new Promise((reslove, reject) => {
            if (data && data.length > 0) {
                reslove(data);
            } else {
                reject(Error("Promise Rejected"));
            }
        });

        dt.then(
            (data) => {
                setMyData(data);
                setCurrent(
                    data &&
                    data.slice(
                        count.prev,
                        data.length < itemLoadLength ? data.length : itemLoadLength
                    )
                );
                setCount({
                    prev: 0,
                    next:
                        data && data.length < itemLoadLength ? data.length : itemLoadLength,
                });
            },
            (err) => {
                console.log(err);
            }
        );

    }, [data]);


    const dataSlice = (data) => {

    }


    const getMoreDt = () => {

        if ((current && current.length) === (mydata && mydata.length)) {
            setHasMore(false);
            return;
        }

        setTimeout(() => {
            setCurrent(
                current &&
                current.concat(
                    mydata &&
                    mydata.slice(
                        count.prev + itemLoadLength,
                        count.next + itemLoadLength > mydata.length
                            ? mydata.length
                            : count.next + itemLoadLength
                    )
                )
            );
        }, 10);
        setCount((prevState) => ({
            prev: prevState.prev + itemLoadLength,
            next: prevState.next + itemLoadLength,
        }));
        if (hasMore) {
            setLoading("Loading...");
        }
    };

    const search = (e) => {
        let text = e.target.value
        // setSearchQuery(text)
        // const filteredData = data.filter(item => item.name.toLowerCase().includes(text.toLowerCase()));
        // console.log('arrow name', filteredData)
        // setCurrent(filteredData)

        // const users = {
        //     John: { username: 'johncam112', age: 19 },
        //     Daniel: { key: 'Dandandel1', age: 21 },
        //     Ruth: { key: 'rutie01', age: 24 },
        //     Joe: { key: 'Joemathuel', age: 28 }
        // };

        // const selectedUsers = [text];

        const filteredUsers = Object.keys(data)
            .filter(key => text.includes(key))
            .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
          }, {});

        // const filteredUsers = data.filter(f => f.toString().indexOf(text) > -1);

        // const filteredUsers = data.filter(function (str) { return str.includes(text); })

        console.log(filteredUsers);

    };

    return (
        <Box>
            <Box sx={{ margin: '4px', p: 1, bgcolor: '#fff' }}>
                <TextField id="outlined-basic" onChange={search} label="Search" variant="outlined" size="small" fullWidth />
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                style={{ overflow: "scroll" }}
                height={windowHeight - 120}
                id="scrollableDiv"
            >

                {loader &&
                    <Box sx={{ width: '100%', height: windowHeight - 65, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed' }}>
                        <CircularProgress />
                    </Box>
                }
                <InfiniteScroll
                    dataLength={current && current.length}
                    pullDownToRefreshThreshold={50}
                    next={getMoreDt}
                    hasMore={hasMore}
                    loader={<h3>{loading}</h3>}
                    scrollableTarget="scrollableDiv"
                >
                    {current && current.map((item, index) =>
                        <Box sx={{ margin: '4px', p: 1, bgcolor: '#fff' }} key={item.id}>

                            <Grid container>
                                <Grid item xs={11} sx={{ pt: 2, pb: 2 }}>
                                    <Grid item md={3} sx={{ display: 'flex' }} >
                                        <Typography sx={{ fontWeight: 'bold' }} >
                                            Purchse Id :
                                        </Typography>
                                        <Typography>
                                            {item.id}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3} sx={{ display: 'flex' }}>
                                        <Typography sx={{ fontWeight: 'bold' }}  >
                                            Date :
                                        </Typography>
                                        <Typography>
                                            {moment(item.purchase_date).format('LLL')}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3} sx={{ display: 'flex' }}>
                                        <Typography sx={{ fontWeight: 'bold' }}  >
                                            Party :
                                        </Typography>
                                        <Typography>
                                            {item.partyModels?.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', pr: 2 }}>
                                    <MoreVertIcon fontSize="small" onClick={(e) => handleClick(e, item)} />
                                </Grid>
                            </Grid>


                        </Box>
                    )}
                </InfiniteScroll>

                {/* edit menu */}
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >

                    <MenuItem sx={{ fontSize: 15, height: 20 }} onClick={() => {
                        handleClose()
                        action.edit(editData)
                    }}>
                        <Icons icon='EditIcon' />
                        Edit

                    </MenuItem>
                    <MenuItem sx={{ fontSize: 15, height: 20 }} onClick={() => {
                        handleClose()
                        action.delete(editData.id)
                    }}>
                        <Icons icon='DeleteIcon' />
                        Delete

                    </MenuItem>
                </StyledMenu>

            </Box>


            {/* <AlertDialog
                open={open}
                setOpen={setOpen}
                action={() => handelDeleteUser(selectId)}
            /> */}
        </Box>
    )


}

export { DataTableMobile };
