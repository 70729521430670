import React, { useEffect, useState } from 'react'
import SideBarItem from './SideBarItem'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, List } from '@mui/material';
import { resetSidebar } from '../../globalSlice';
import logo from '../../assets/images/logo.png';

function SideBarContainer() {
    const [closeStatus, setCloseStatus] = useState(true)
    const dispatch = useDispatch();
    const projectName = useSelector((state) => state.global.projectName);
    const sideBarStatus = useSelector((state) => state.global.sideBarIsOpen);

    const routes = useSelector((state) => state.global.routes);
    const leftMenus = routes.filter((menu) => menu.location === 'Left');

    const collapse = () => {
        setCloseStatus(!closeStatus)
    }

    return (
        <Box className={`sidebar ${sideBarStatus && "close"}`} sx={{ backgroundColor: 'primary.Cdark' }}>
            <div className="logo-details" style={{ cursor: 'pointer', background: '#000' }} onClick={collapse}>
                <img src={logo} title={projectName} width={60} alt={projectName} />
                <Box className="logo_name" component="span" sx={{ color: 'text.main', padding: '10px 5px' }}>{projectName}</Box>
            </div>

            <List className="nav-links" component="nav"
                aria-labelledby="nested-list-subheader">
                {/* menu item */}
                {leftMenus && leftMenus.map((item, index) => <SideBarItem key={index} item={item} />)}


                {/* <li>
                    <div className="profile-details">
                        <div className="profile-content">
                            <img src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?w=740&t=st=1688541168~exp=1688541768~hmac=cbcb31bb051fbe025772e6d76ac2669514dde29009855783838e14ca557380d3" alt="profileImg" className='profileImage' />
                        </div>
                        <div className="name-job">
                            <div className="profile_name">Sarwar Jahan</div>
                            <div className="job">Programer</div>
                        </div>
                        <i className='bx bx-log-out' ></i>
                    </div>
                </li> */}
            </List >

        </Box>
    )
}

export default SideBarContainer