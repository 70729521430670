import React, { useContext, useState } from 'react';
import { Box, Button, TextField, Typography, Link, CircularProgress, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setLogin, setRoute, setDialogStatus, setDialogMessage } from '../../globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import bg_accounts from '../../assets/images/bg_accounting.png';
import Icons from '../Master/Icons';
import urlPath from '../../constants/path/urlPath';
import logo from '../../assets/images/logo.png';


const Desktop = () => {
    const projectName = useSelector((state) => state.global.projectName);
    const isLogin = useSelector((state) => state.global.isLogin);
    const routes = useSelector((state) => state.global.routes);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loadingLogin, setLoadingLogin] = useState(false);
    const [loginModel, setLoginModel] = useState({
        userId: "",
        errorUserId: false,
        helperTextUserId: "",
        password: "",
        errorPassword: false,
        helperTextPassword: "",
    });


    const hendleLogin = (event) => {

        setLoginModel({
            ...loginModel,
            errorUserId: true,
            helperTextUserId: "",
            errorPassword: false,
            helperTextPassword: "",
        });


        if (loginModel.userId.length === 0) {
            setLoginModel({
                ...loginModel,
                errorUserId: true,
                helperTextUserId: "The user Id is requried"
            });
            return;
        }

        if (loginModel.password.length === 0) {
            setLoginModel({
                ...loginModel,
                errorPassword: true,
                helperTextPassword: "The user Id is requried"
            });
            return;
        }


        setLoadingLogin(true);
        axios.post(urlPath.account.auth, {
            loginId: loginModel.userId,
            password: loginModel.password,
        }).then(function (response) {
            axios.defaults.headers.Authorization = 'Bearer ' + response.data.jwtToken;
            axios.get(urlPath.account.navigation).then(function (response1) {
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("userProfileTemplate", JSON.stringify(response1.data));

                dispatch(setRoute(response1.data));
                dispatch(setLogin(true));
                setLoadingLogin(false);
                navigate("/home");
            })
                .catch(function (error) {
                    dispatch(setDialogMessage({ status: 'error', message: error.response.data }));
                    setLoadingLogin(false);
                });
        })
            .catch(function (error) {
                console.log(error);
                dispatch(setDialogMessage({ status: 'error', message: error.response.data }));
                setLoadingLogin(false);
            });
    };

    const hendleForget = (event) => {
        navigate("/forget-password");
    };


    return (
        <>
            <Box display="flex" justifyContent="center" alignItems="center" height={"100vh"} style={{ background: 'url(' + bg_accounts + ') center center no-repeat', backgroundSize: 'cover' }} >
                <Box width="350px" style={{ backgroundColor: '#fff', color: "#000", padding: '32px', borderRadius: '8px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px' }}  >
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box display="flex" flexDirection={"column"} style={{ backgroundColor: '#000', justifyContent: "center", alignItems: "center", borderRadius: '8px' }} width={120} >
                            <Box display="flex" style={{ justifyContent: "center", alignItems: "center" }} height={100}>
                                <img src={logo} alt="" width={"100"} style={{ backgroundColor: '#fff', borderRadius: '4px' }} />
                            </Box>
                            <Typography variant="h7" color="primary" style={{ fontWeight: 'bold', backgroundColor: '#fff', width: "80px", textAlign: 'center', borderRadius: '4px' }}  >
                                {projectName}
                            </Typography>
                            <Box height="10px"></Box>
                        </Box>
                    </Box>
                    <Box flexGrow={1} height="20px"></Box>
                    <Box textAlign="center">
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}  >
                            Login to Start Work
                        </Typography></Box>
                    <Box flexGrow={1} height="20px"></Box>
                    <TextField type={"text"} label="User Id"
                        value={loginModel.userId}
                        error={loginModel.errorUserId}
                        helperText={loginModel.helperTextUserId}
                        onChange={(event) => setLoginModel({ ...loginModel, userId: event.target.value })}
                        onKeyUp={(event) => {
                            setLoginModel({
                                ...loginModel,
                                errorUserId: false,
                                helperTextUserId: "",
                            });

                            if (loginModel.userId.length < 8) {
                                setLoginModel({
                                    ...loginModel,
                                    errorUserId: true,
                                    helperTextUserId: "The user Id should be 8 digit"
                                });
                            }
                        }}
                        placeholder="Enter user id" variant="outlined" fullWidth />

                    <Box height="20px"></Box>
                    <TextField type="password" label="Password"
                        value={loginModel.password}
                        error={loginModel.errorPassword}
                        helperText={loginModel.helperTextPassword}
                        onChange={(event) => setLoginModel({ ...loginModel, password: event.target.value })}
                        placeholder="Enter your password" variant="outlined" fullWidth />

                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1}></Box>
                        <Button variant="text" color="primary" onClick={hendleForget} >Forget Password?</Button>
                    </Box>
                    <Box flexGrow={1} height="20px"></Box>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1}></Box>
                        {loadingLogin ? <CircularProgress /> : <Button variant="contained" color="primary" startIcon={<Icons icon="LogoutIcon" />} onClick={hendleLogin}>LOGIN</Button>}
                    </Box>
                    <Box flexGrow={1} height="10px"></Box>
                    <Box display="flex" style={{ justifyContent: "center" }} >
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', paddingRight: '5px' }}   >
                            Not Registered?
                        </Typography>
                        <Link href="/sign-up" underline="none"> Sign Up Now</Link>
                    </Box>
                </Box>
            </Box>
        </>
    );


    const getAppRouter = (items) => {
        // console.log(items);
        var outItems = [];
        if (items.length > 0) {
            outItems.push({
                name: "home",
                route: '/home',
                location: 'None',
            });
        }

        // items.map((item, index)=>{

        // });

        return outItems;
    };
}

export { Desktop };
