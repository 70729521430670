import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import React, { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { AccountPayableContext } from "../../components/FA/AccountingVoucher/AccountPayable/context/AccountPayableContextProvide";

function PopupModal(props) {
  const windowSize = React.useRef([window.innerWidth, window.innerHeight]);
  const windowHeight = windowSize.current[1];
  const { state, setState, closeFn = null, vatTextFlag = true, title = '' } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "98%",
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: 3,
    boxShadow: 24,
    minHeight: windowHeight - 10,
  };

  return (
    <>
      <Modal
        open={state}
        onClose={() => setState(!props.state)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: 'space-between',
              borderBottom: '1px solid #ddd',
            }}
          >
            <Typography component={"p"} sx={{ m: 1 }} >
              <b>{title}</b>
            </Typography>

            <IconButton
              aria-label="delete"
              sx={{
                backgroundColor: "#ff000050",
                height: 25,
                width: 25,
                m: 1,
                boxShadow: 1,
                border: 1,
                borderColor: "#ff00008e",
              }}
              onClick={closeFn ? closeFn : () => setState(!props.state)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {props.children}
        </Box>
      </Modal>
    </>
  );
}

export default PopupModal;
