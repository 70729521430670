import React from "react";
import { Box, CircularProgress, CssBaseline } from '@mui/material';
import Main from './components/Main';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { blue, orange } from '@mui/material/colors';
import Dialog from './Utlity/Dialog';
import DialogLogin from './Utlity/DialogLogin';
import aafColor from '../src/Utlity/aafColor'
import AlertDailog from "./Utlity/alert/AlertDailog";
import CustomizedSnackbars from "./Utlity/snackBar/customSnackBar";
import { useDispatch, useSelector } from 'react-redux';


const theme = createTheme({
  palette: {
    primary: {
      main: aafColor[900],
      ornage: '#FF5100',
      Cdark: '#11101d',
      white: '#ffffff',
      semiDark: '#e4e4e4'
      // contrastText: '#111',
    },
    secondary: {
      main: aafColor[500],

      // contrastText: '#111',
    },
    text: {
      main: '#f4f4f4',
      primary: "#111"
    },
    chip: {
      success: '#00800034',
      error: '#ff00002d'

    },
    avatar: {
      ornage: '#ff5100a9'
    }
  },
});


function App() {
  document.title = "Admin";
  let circularLoder = useSelector((state) => state.global.circularLoder);
  return (
    <Box style={{ backgroundColor: '#ccc', height: "100vh" }}>
      <ThemeProvider theme={theme}>
        <Main />
        {/* <DialogLogin /> */}
        <Dialog />
        <AlertDailog />
        {<CustomizedSnackbars />}

        {circularLoder &&
          <Box sx={{ backgroundColor: '#11101d46', position: 'fixed', top: 0, zIndex: 10000, width: '100%', height: window.innerHeight, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ backgroundColor: 'primary.white', height: 80, width: 80, justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '5px', boxShadow: 2 }}>
              <CircularProgress />
            </Box>
          </Box>
        }

      </ThemeProvider>
      <CssBaseline />
    </Box>
  );
}

export default App;


// import { useDispatch, useSelector } from "react-redux";
// import { fetchTodos } from "./slice/todo";

// function App() {
//   const dispatch = useDispatch();
//   const state = useSelector((state) => state);

//   console.log("State", state);

//   if (state.todo.isLoading) {
//     return <h1>Loading....</h1>;
//   }

//   return (
//     <div className="App">
//       <button onClick={(e) => dispatch(fetchTodos())}>Fetch Todos</button>
//       {state.todo.data && state.todo.data.map((e) => <li>{e.title}</li>)}
//     </div>
//   );
// }

// export default App;