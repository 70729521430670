import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, Button, CircularProgress, Grid, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import Icons from "../../Master/Icons";


const Product = ({ rows, setParentModel, setEditProduct, modelFunction }) => {

    const BasicTable = ({ rows }) => {
        return (
            <TableContainer component={Paper}>
                <Table size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width={"10%"}><strong>SL</strong></TableCell>
                            <TableCell width={"30%"}><strong>Product</strong></TableCell>
                            <TableCell width={"15%"} align="right"><strong>Qty</strong></TableCell>
                            <TableCell width={"15%"} align="right"><strong>Price</strong></TableCell>
                            <TableCell width={"15%"} align="right"><strong>Pices</strong></TableCell>
                            <TableCell width={"15%"} align="right"><strong>Total</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell align="left">
                                    {row.productModels?.name}
                                </TableCell>
                                <TableCell align="right">{row.qty}</TableCell>
                                <TableCell align="right">{row.purchase_price}</TableCell>
                                <TableCell align="right">{row.pices}</TableCell>
                                <TableCell align="right">{(row.qty * row.purchase_price).toLocaleString(undefined, { minimumFractionDigits: 2 })}</TableCell>
                            </TableRow>
                        ))}
                        {rows && rows.length > 1 &&
                            <TableRow>
                                <TableCell colSpan={2}><strong>Subtotal</strong></TableCell>
                                <TableCell align="right">{rows.map(({ qty }) => qty).reduce((sum, i) => sum + i, 0).toLocaleString()}</TableCell>
                                <TableCell colSpan={2}></TableCell>
                                <TableCell align="right">{rows.map((row) => row.qty * row.purchase_price).reduce((sum, i) => sum + i, 0).toLocaleString(undefined, { minimumFractionDigits: 2 })}</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    const handelDelete = (row_id) => {
        setParentModel((prevState) => {
            var data = prevState.purchaseChildModels;
            const newList = data.filter((item) => item.row_id !== row_id);
            newList.map((valItme, index) => {
                valItme.row_id = index
            });
            return {
                ...prevState,
                purchaseChildModels: newList
            };
        })
    }

    const handelEdit = (product) => {
        modelFunction.handelShowProduct(true)
        modelFunction.handelEditProduct(product)
    }


    return (
        <>
            {rows && rows.map((row, index) => (
                <Grid container key={index} sx={{ borderBottom: '1px solid #ccc' }} >
                    <Grid xs={12} md={1}>
                        <strong>SL : </strong>{index + 1}
                    </Grid>
                    <Grid xs={12} md={4}>
                        <strong>Name : </strong>{row.productModels?.name}
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <strong>Pices : </strong> {row.pices}
                    </Grid>
                    <Grid item xs={12} md={1} sx={{ display: 'flex' }}>
                        <strong>Qty : </strong> {row.qty}
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ display: 'flex' }}>
                        <strong> Cost : </strong> {row.purchase_price}
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ display: 'flex' }}>
                        <strong> Total : </strong> {(row.qty * row.purchase_price).toLocaleString(undefined, { minimumFractionDigits: 0 })}
                    </Grid>
                    <Grid item xs={12} md={1} sx={{ display: 'flex' }}>
                        <IconButton onClick={() => handelEdit(row.productModels)} size="small">
                            <Icons icon='EditIcon' />
                        </IconButton >
                        <IconButton onClick={() => handelDelete(row.row_id)} color="warning" size="small">
                            <Icons icon='DeleteIcon' />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
        </>
    )
}

export { Product };
