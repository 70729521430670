import React from "react";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopy from '@mui/icons-material/ContentCopy';
import LogoutIcon from '@mui/icons-material/Logout';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NavigationIcon from '@mui/icons-material/Navigation';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileOpenIcon from '@mui/icons-material/FileOpen';

const Icons = ({ icon }) => {
    const icons = iconList.filter((item) => item.name === icon)
    return icons.length > 0 ? icons[0].icon : <KeyboardDoubleArrowRightIcon />
}

export default Icons;


const iconList = [
    {
        name: "warning",
        icon: <WarningAmberIcon />
    },
    {
        name: "SaveIcon",
        icon: <SaveIcon />
    },
    {
        name: "CloseIcon",
        icon: <CloseIcon />
    },
    {
        name: "FilterListIcon",
        icon: <FilterListIcon />
    },
    {
        name: "AddIcon",
        icon: <AddIcon />
    },
    {
        name: "NavigationIcon",
        icon: <NavigationIcon />
    },
    {
        name: "EditIcon",
        icon: <EditIcon />
    },
    {
        name: "DeleteIcon",
        icon: <DeleteIcon />
    },
    {
        name: "PhotoCamera",
        icon: <PhotoCamera />
    },
    {
        name: "KeyboardDoubleArrowRightIcon",
        icon: <KeyboardDoubleArrowRightIcon />
    },
    {
        name: "KeyboardArrowRightIcon",
        icon: <KeyboardArrowRightIcon />
    },
    {
        name: "ExpandLess",
        icon: <ExpandLess />
    },
    {
        name: "ExpandMore",
        icon: <ExpandMore />
    },
    {
        name: "MenuIcon",
        icon: <MenuIcon />
    },
    {
        name: "AccountCircle",
        icon: <AccountCircle />
    },
    {
        name: "SettingsOutlined",
        icon: <SettingsOutlined />
    },
    {
        name: "InboxIcon",
        icon: <InboxIcon />
    },
    {
        name: "SearchIcon",
        icon: <SearchIcon />
    },
    {
        name: "ContentCopy",
        icon: <ContentCopy />
    },
    {
        name: "LogoutIcon",
        icon: <LogoutIcon />
    },
    {
        name: "PlaylistAddCheckIcon",
        icon: <PlaylistAddCheckIcon />
    },
    {
        name: "MoreVertIcon",
        icon: <MoreVertIcon />
    },
    {
        name: "FileOpenIcon",
        icon: <FileOpenIcon />
    }
];

