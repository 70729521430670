import * as React from 'react'
import { Box, Button, TextField, Tooltip, FormLabel, Checkbox, Autocomplete, Grid, CircularProgress, MenuItem, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from "@mui/material"
import Icons from '../../Master/Icons'
import { useForm } from "react-hook-form"
import { useSelector } from 'react-redux'
import urlPath from '../path/FaPath'
import FormHeader from '../../../Utlity/FormHeader'
import dayjs from "dayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import moment from "moment"
import { setLoader, setCircularLoader, setNotification, setDialogMessage } from '../../../globalSlice'
import { Product } from './Product'
import AddProduct from './AddProduct'
import AddProductPopup from './AddProductPopup'
import useWindowSize from '../../../Utlity/useWindowSize'
import PopupModal from '../../../Utlity/modal/PopupModal'

const EntryForm = ({ modelFunction }) => {
    const [purchaseDate, setPurchaseDate] = React.useState()
    const today = dayjs()
    const [inputParty, setInputParty] = React.useState("")
    const [model, setModel] = React.useState({
        id: 0,
        accounts_id: 1,
        account_code: 0,
        party: null,
        purchase_date: today,
        source: "",
        note: "",
        others_add: 0,
        net_purchase: 0,
        payment: 0,
        due: 0,
        purchaseChildModels: []
    })
    // const [isAddProduct, setIsAddProduct] = React.useState(false)


    React.useEffect(() => {
        let item = modelFunction?.data?.item
        if (item) {
            setPurchaseDate(dayjs(item.purchase_date));
            setModel({
                ...model,
                id: item.id,
                party: item.partyModels,
                purchase_date: item.purchase_date,
                note: item.note,
                purchaseChildModels: item.purchaseChildModels
            })
        } else {
            setPurchaseDate(today);
            setModel({
                ...model,
                id: 0,
                party: { code: 0, name: '' },
                purchase_date: '',
                note: '',
                purchaseChildModels: []
            })
        }
    }, [modelFunction?.data?.item])

    const onSubmit = () => {
        modelFunction.handelSave({
            ...model,
            purchase_date: moment(purchaseDate.$d).format("YYYY-MM-DD") + `T00:00:00`,
            account_code: model.party.id,
            note: model.note,
            net_purchase: model?.purchaseChildModels?.reduce((sum, m) => sum = sum + (m.qty * m.purchase_price), 0)
        })
    }

    const handelAddProduct = () => {

        modelFunction.handelEditProduct({
            id: 0,
            name: "",
            pices: "",
            qty: "",
            purchasePrice: "",
            serial_no: '',
            product_id: 0,
            row_id: 0
        })
        modelFunction.handelShowProduct(true)
    }


    return (
        <FormHeader
            title={modelFunction.data.formTitle}
            setState={modelFunction.setShowBottomDialog}
            state={modelFunction.data.showBottomDialog} >
            {modelFunction?.data.entryFormLoader &&
                <Box sx={{ height: window.innerHeight - 120, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            }

            {!modelFunction?.data.entryFormLoader && <>
                <Box sx={{ p: 1, height: window.innerHeight - 120, overflow: 'auto' }}>
                    <fieldset>
                        <legend>   Purchase Summary</legend>
                        <Grid container >
                            <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        defaultValue={today}
                                        format="DD-MM-YYYY"
                                        views={["year", "month", "day"]}
                                        slotProps={{
                                            textField: {
                                                label: "Date",
                                                size: "small",
                                                fullWidth: true,
                                                // error: errors?.purchase_date ? true : false,
                                            },
                                        }}
                                        value={purchaseDate}
                                        onChange={(value) => setPurchaseDate(value)}
                                    />
                                    {/* </DemoContainer> */}
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <Autocomplete
                                    // onChange={handelPartyChange}
                                    size="small"
                                    options={modelFunction.data.partyList}
                                    fullWidth
                                    // defaultValue={party}
                                    value={model.party}
                                    inputValue={inputParty}
                                    onChange={(event, newValue) => setModel({ ...model, party: newValue })}
                                    onInputChange={(event, newInputValue) => setInputParty(newInputValue)}
                                    id="PartyAutocomplete"
                                    getOptionLabel={(option) => option?.name}
                                    // onInputChange={(event) => handelPartyInputChange(event)}
                                    // renderInput={(params) => <TextField {...params} label="Party" />}
                                    renderInput={(params) => (
                                        <TextField {...params} label={"Party"} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ p: 1 }} >
                                <TextField
                                    name="narration"
                                    label={"Narration"}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={model.note}
                                    onChange={(event) => setModel({ ...model, note: event.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </fieldset>

                    <fieldset>
                        <legend>   Add Product</legend>

                        <Grid item xs={12} sx={{ display: { xs: 'flex', sm: 'none' }, p: 1 }}>
                            <Tooltip title="Add Product" >
                                {/* <Button onClick={() => setIsAddProduct((preState) => !preState)} type="button" variant="contained" color="secondary" size='small' > */}
                                <Button onClick={handelAddProduct} type="button" variant="contained" color="secondary" size='small' >
                                    <Icons icon='AddIcon' />
                                    Add Product
                                </Button>
                            </Tooltip>
                            <AddProductPopup parentModel={model} setParentModel={setModel} modelFunction={modelFunction} />
                        </Grid>

                        <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'flex' }, p: 1 }}>
                            <AddProduct parentModel={model} setParentModel={setModel} modelFunction={modelFunction} />
                        </Grid>

                    </fieldset>

                    <fieldset>
                        <legend>  Product Details</legend>
                        <Grid item xs={12} sx={{ display: 'flex', p: 1, flexFlow: 'column' }}>
                            <Product rows={model?.purchaseChildModels} setParentModel={setModel} modelFunction={modelFunction} />
                        </Grid>
                    </fieldset>
                </Box>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, bgcolor: '#EEE' }}>
                    <Tooltip title="Save">
                        <Button type="submit" variant="contained" color="primary" size='small' onClick={onSubmit} >
                            <Icons icon='SaveIcon' />
                            Save
                        </Button>
                    </Tooltip>
                </Grid>
            </>}

        </FormHeader >
    )
}

export default EntryForm
