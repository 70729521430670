import React, { useContext, useEffect, useState } from "react";
import { DataTable } from "./DataTable";
import FloatingAddButton from "../../../Utlity/addButton/FloatingAddButton";
import EntryForm from "./EntryForm";

const Desktop = ({ modelFunction }) => {
    return (
        <>
            <DataTable
                data={modelFunction.data.itemList}
                title={'Purchase List'}
                loader={modelFunction.loading}
                action={{
                    delete: modelFunction.handelDeleteConfirm,
                    edit: modelFunction.handelEdit,
                    preview: modelFunction.handelPreview
                }}
            />
            <EntryForm modelFunction={modelFunction} />
            <FloatingAddButton action={modelFunction.handelAdd} />
        </>
    )
}

export { Desktop };
