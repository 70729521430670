import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, Button, CircularProgress, Grid, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import moment from 'moment';

const MasterInfo = ({ item }) => {
    return (
        <Grid container>
            <Grid item xs={12} md={1} sx={{ display: 'flex' }} >
                <Grid sx={{ fontWeight: 'bold' }} >
                    Id :
                </Grid>
                <Grid>
                    {item.id}
                </Grid>
            </Grid>
            <Grid item xs={12} md={3} sx={{ display: 'flex' }} >
                <Grid sx={{ fontWeight: 'bold' }}  >
                    Date :
                </Grid>
                <Grid>
                    {moment(item.sale_date).format('MMM Do YY')}
                </Grid>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex' }} >
                <Grid sx={{ fontWeight: 'bold' }}>
                    Party :
                </Grid>
                <Grid>
                    {item.partyModels?.name}
                </Grid>
            </Grid>
            <Grid item xs={12} md={2} sx={{ display: 'flex' }} >
                <Grid sx={{ fontWeight: 'bold' }}  >
                    Creator :
                </Grid>
                <Grid>
                    {item.modify_user}
                </Grid>
            </Grid>
            <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: { xs: 'start', sm: 'space-between' } }}  >
                <Grid sx={{ fontWeight: 'bold' }} >
                    Total :
                </Grid>
                <Grid>
                    {item.net_sale.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                </Grid>
            </Grid>
        </Grid>
    )
}

export { MasterInfo };
