import React from "react";
import { Box, Link } from "@mui/material";
import { useSelector } from 'react-redux'
import NestedList from "./NestedList";
import logo from '../../assets/images/logo.png';
// import "./Master.css"

const LeftSidebar = () => {
    const projectName = useSelector((state) => state.global.projectName);
    const leftWidth = useSelector((state) => state.global.leftWidth);
    const sidebarIsOpen = leftWidth === 250 ? true : false;
    return (


        <Box width={leftWidth} style={{ backgroundColor: '#000', color: 'white', transition: 'width 500ms' }} >
            <Box position={"relative"} style={{ backgroundColor: '#111' }} >
                <Link href="#" sx={{ display: 'flex', alignItems: 'center', height: '60px', color: '#fff' }} underline="none" fontSize={20}  >
                    <Box style={{ backgroundColor: '#fff', borderRadius: '2px', marginLeft: '4px' }}><img src={logo} title={projectName} width={50} alt={projectName} /></Box>
                    <Box sx={{ display: !sidebarIsOpen ? 'none' : 'block' }} paddingLeft={1} alignItems={"center"} >{projectName}</Box>
                </Link>
                <NestedList />
            </Box>
        </Box>
    );
};

export default LeftSidebar;
