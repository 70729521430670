import React, { useEffect, useRef, useState } from "react";
import { DataTableMobile } from "../../../Utlity/dataTable/DataTableMobile";
import EntryForm from "./EntryForm";
import FloatingAddButton from "../../../Utlity/addButton/FloatingAddButton";

const Mobile = ({ modelFunction }) => {
    return (
        <>
            <DataTableMobile
                data={modelFunction.data.itemList}
                loader={modelFunction.loading}
                action={{
                    delete: modelFunction.handelDeleteConfirm,
                    edit: modelFunction.handelEdit
                }}
            />
            <EntryForm modelFunction={modelFunction}  />
            <FloatingAddButton action={modelFunction.handelAdd} />
        </>
    )
}
export { Mobile };
