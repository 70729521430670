import React from "react";
import { List, ListSubheader } from '@mui/material';
import { useSelector } from 'react-redux';
import SidebarItem from "./SidebarItem";

export default function NestedList() {

    const leftWidth = useSelector((state) => state.global.leftWidth);
    const routes = useSelector((state) => state.global.routes);
    const sidebarIsOpen = leftWidth === 250 ? true : false;
    const leftMenus = routes.filter((menu) => menu.location === 'Left');

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader"
                    style={{ backgroundColor: '#222', color: '#bbb', height: '30px', lineHeight: '28px', width: leftWidth }}
                >
                    {!sidebarIsOpen ? "" : "MAIN NAVIGATION"}
                </ListSubheader>
            }
        >
            {leftMenus.map((item, index) => <SidebarItem key={index} item={item} sidebarIsOpen={sidebarIsOpen} level={1} />)}
        </List>
    );
}