import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


// const anonymousRoutes = [
//     {
//         name: "Login",
//         route: '/login',
//         location: 'None',
//     }, {
//         name: "Registration",
//         route: '/registration',
//         location: 'None',
//     }, {
//         name: "Forget Password",
//         route: '/rorget-password',
//         location: 'None',
//     },
// ];

// const getToken = () => {
//     const tokenString = localStorage.getItem('jwtToken');
//     const userToken = JSON.parse(tokenString);
//     return userToken?.token
// };

// const getRoute = () => {
//     const routeString = localStorage.getItem('routes');
//     const routes = JSON.parse(routeString);
//     return routes == null ? anonymousRoutes : routes
// };


// export const fetchTodos = createAsyncThunk("fetchTodos", async () => {
//     const response = await fetch("https://jsonplaceholder.typicode.com/todos");
//     return response.json();
//   });

let sidebarStatus = sessionStorage.getItem('sideba')

console.log('side bar status', sidebarStatus)

const initialState = {
    projectName: "POS",
    // value: 250,
    isLogin: false,
    loadingSataus: false,
    circularLoder: false,
    pageName: '',
    snackBarStatus: false,
    snack: {
        message: '',
        type: ''
    },

    confirmDailog: {
        status: '',
        message: '',
        action: ''
    },

    routes: [],
    leftWidth: 250,
    token: '',
    dialogMessage: { status: '', message: '' },
    apiStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    apiMessage: '',
    apiData: [],
    sideBarIsOpen: sidebarStatus ? sidebarStatus == 'false' ? false : true : false
    // rightMenus: []
}

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setPageName: (state, action) => {
            state.pageName = action.payload
        },
        setConfirmDailog: (state, action) => {
            state.confirmDailog = action.payload
        },
        setNotification: (state, action) => {
            state.snackBarStatus = true
            state.snack = action.payload
        },
        closeNotification: (state) => {
            state.snackBarStatus = false
            state.snackText = ""
        },
        setCircularLoader: (state, action) => {
            state.circularLoder = action.payload
        },
        setLoader: (state, action) => {
            state.loadingSataus = action.payload
        },
        resetSidebar: (state) => {
            state.leftWidth = state.leftWidth === 250 ? 60 : 250
            let sideBarStatus = !state.sideBarIsOpen
            state.sideBarIsOpen = sideBarStatus
            sessionStorage.setItem('sideba', sideBarStatus);
        },
        setRoute: (state, action) => {
            state.routes = action.payload
        },
        setLogin: (state, action) => {
            state.isLogin = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setDialogMessage: (state, action) => {
            state.dialogMessage = action.payload
        },
        getApiData: (state, action) => {
            return {
                ...state,
                apiStatus: 'loading',
                apiData: action.payload
            }
        }
    },
})

export const { setPageName, setConfirmDailog, closeNotification, setNotification, setCircularLoader, setLoader, resetSidebar, setLogin, setRoute, setToken, setDialogMessage, getApiData } = globalSlice.actions

export default globalSlice.reducer