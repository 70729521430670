import React, { useEffect, useState } from "react"
import { Layout as MasterLayout } from '../../Master/Layout';
import { Desktop } from './Desktop';
import useSendRequest from "../../../constants/customeHelper/useSendRequest";
import { setConfirmDailog, setDialogMessage, setCircularLoader, setNotification } from "../../../globalSlice";
import { useDispatch, useSelector } from "react-redux";


const Layout = () => {
    const dispatch = useDispatch()
    const { getApiRequest, sendPrintRequest } = useSendRequest()
    const [loader, setLoader] = useState(false)

    const [data, setData] = useState({
        itemList: [],
        item: null,
        partyList: [],
        productList: [],
        productTypeList: [],
        paymentMethodList: []
    })

    const modelFunction = {
        data: data,
        loading: loader,
        handelPreview: (val) => {
            dispatch(setCircularLoader(true))
            getApiRequest("rp/InventoryReport/InvReport", "previewPost", val).then((returnVal) => {
                const pdfBlob = new Blob([returnVal], { type: "application/pdf" });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl);
                dispatch(setCircularLoader(false))
            }).catch(err => {
                console.log(err)
                dispatch(setCircularLoader(false))
                dispatch(setDialogMessage({ status: 'error', message: err?.response?.data }))
            })
        },
        getApiRequest: getApiRequest
    }

    let isInitRequest = true;
    useEffect(() => {
        if (isInitRequest) {
            isInitRequest = false
            // dispatch(setCircularLoader(true))
            setLoader(true)
            // var modelItem = modelFunction.data.item
            setTimeout(() => {
                Promise.all([
                    getApiRequest("po/Report/ReportList").then((value) => setData((prev) => {
                        return {
                            ...prev,
                            itemList: value.sort((a, b) => b.id - a.id),
                        };
                    })),
                    getApiRequest("po/party").then((value) => setData((prev) => {
                        return {
                            ...prev,
                            partyList: value.sort((a, b) => b.id - a.id),
                        };
                    })),
                    getApiRequest("po/product").then((value) => setData((prev) => {
                        return {
                            ...prev,
                            productList: value.sort((a, b) => b.id - a.id),
                        };
                    })),
                    getApiRequest("po/paymentMethod").then((value) => setData((prev) => {
                        return {
                            ...prev,
                            paymentMethodList: value.sort((a, b) => b.id - a.id),
                        };
                    }))
                ]).then(() => {
                    // dispatch(setCircularLoader(false))
                    setLoader(false)
                }).catch((err) => {
                    dispatch(setDialogMessage({ status: 'error', message: err }))
                })
            }, 10)
        }

    }, [])

    return (
        <>
            <MasterLayout><Desktop modelFunction={modelFunction} /></MasterLayout>
        </>
    )
}

export { Layout };
