import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function WelcomePage() {
    const navigate = useNavigate();

    let tokenString = localStorage.getItem('user');
    useEffect(() => {
        console.log(tokenString);
        if (tokenString === null || tokenString === "null" || tokenString === "") {
            return navigate('/login')
        } else {
            return navigate('/home')
        }

    }, [tokenString])

}

export default WelcomePage