import React, { useEffect, useState } from 'react'
import { createContext } from 'react'
import FaPath from "../path/FaPath"
import useSendRequest from "../../../constants/customeHelper/useSendRequest";
import { setConfirmDailog, setDialogMessage } from "../../../globalSlice";
import { useDispatch, useSelector } from "react-redux";


export const ModelContext = createContext(null)
function ModelContextProvide(props) {
    const dispatch = useDispatch();
    const [data, setData] = useState({
        itemList: [],
        item: null,
        formTitle: "",
        showBottomDialog: false,
        showProductDialog: false,
        partyList: [],
        productList: [],
    });

    const {
        sendPostRequest,
        sendPutRequest,
        sendDeleteRequest,
        sendCustomGetRequest,
        response,
        loading
    } = useSendRequest()

    const handelDelete = async (accountId, id) => {
        await sendDeleteRequest(FaPath.purchase.delete + accountId + '/' + id).then(valu => {
            console.log('delete done')
        }).catch(err => {
            console.log('delete error', err.message)
        });
    }

    const modelFunction = {
        handelDeleteConfirm: (accountId, id) => {
            dispatch(setConfirmDailog({
                type: 'error',
                message: "Are you sure you want to delete",
                action: () => handelDelete(accountId, id)
            }));
        },
        handelEdit: (val) => {
            setData((prev) => {
                return {
                    ...prev,
                    formTitle: "Edit Purchase",
                    showBottomDialog: true,
                    item: val
                };
            });
        },
        handelAdd: () => {
            setData((prev) => {
                return {
                    ...prev,
                    formTitle: "Add Purchase",
                    showBottomDialog: true,
                    item: null
                };
            });
        },
        setShowBottomDialog: (val) => {
            setData((prev) => {
                return {
                    ...prev,
                    showBottomDialog: val
                };
            });
        },
        data: data,
        loading: loading,
        handelSave: async (fromData) => {
            return await sendPostRequest(FaPath.party.add, fromData)
        },
        handelShowProduct: (val) => {
            console.log(val)
            setData((prev) => {
                return {
                    ...prev,
                    showProductDialog: val
                };
            });
        },
        sendCustomGetRequest: sendCustomGetRequest
    }

    let isInitRequest = true;
    useEffect(() => {

        if (isInitRequest) {
            isInitRequest = false
            // var modelItem = modelFunction.data.item
            try {
                setTimeout(() => {
                    Promise.all([
                        sendCustomGetRequest("po/purchase").then((value) => setData((prev) => {
                            return {
                                ...prev,
                                itemList: value.sort((a, b) => b.id - a.id),
                            };
                        })),
                        sendCustomGetRequest("po/party").then((value) => setData((prev) => {
                            return {
                                ...prev,
                                partyList: value.sort((a, b) => b.id - a.id),
                            };
                        })),
                        sendCustomGetRequest("po/product").then((value) => setData((prev) => {
                            return {
                                ...prev,
                                productList: value.sort((a, b) => b.id - a.id),
                            };
                        }))
                    ]);
                }, 100)
            }
            catch (err) {
                console.log(err);
            }
        }

    }, []);


    const value = { modelFunction }

    return (
        <ModelContext.Provider value={value}>
            {props.children}
        </ModelContext.Provider>
    )
}

export default ModelContextProvide